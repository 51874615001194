/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import Select from 'components/atoms/Select';
import { LangConverter } from 'helpers/common';
import { useTranslation } from 'helpers/useTranslation';
import GridCol from 'components/atoms/GridCol';
import { ServicesHolder } from './CreatePrice.styles';

function CreatePrice({ onClose = () => {}, price }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  const { t } = useTranslation();

  useEffect(() => {
    if (price) {
      const s = { ...price };
      s.resellerId = { label: price?.resellerId?.email, value: price?.resellerId?._id };
      s.serviceId = {
        label: t('All'),
        value: 'all',
      };
      // s.serviceId = {
      //   label: `${t('Service Code')}: ${price?.serviceId?.serviceCode},
      //    ${t('Provider')}: ${price?.serviceId?.providerId?.providerName}`,

      //   value: price?.serviceId?._id,
      //   price: price?.serviceId?.price,
      // };
      form.setFieldsValue(s);
      setState(s);
    }
  }, [price]);

  const handleServicesSearch = async __ => {
    try {
      const response = await adminService.getServices({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.services?.map(_ => ({
        value: _?._id,
        label: LangConverter(
          `Service Code: ${_?.serviceCode}, 
         Provider: ${_?.providerId?.providerName}`,
          lang.value,
        ),
      }));
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  // when provider is selected populate the existing discounts if exists. If all selected then bring all serivces of providers.

  const handleProviderSearch = async __ => {
    try {
      const response = await adminService.getServiceProviders({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.serviceProviders?.map(_ => ({
        value: _,
        label: _?.providerName,
      }));
      options.unshift({ label: 'All', value: 'all' });
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  const handleResellerSearch = async __ => {
    try {
      const response = await adminService.getResellers({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.resellers?.map(_ => ({
        value: _?._id,
        label: _?.email,
      }));
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  const onSubmit = async () => {
    const payload = state?.services?.map(itm => ({
      resellerId: state.resellerId.value,
      serviceId: itm?.value,
      discount: itm?.discount,
    }));
    try {
      setLoading(true);

      const res = await adminService.createPricelist(payload);
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const getServices = async provider => {
    try {
      const res = await adminService.getServiceByProvider(state.resellerId.value, provider);
      const services = res?.map(itm => {
        const discountedPrice = itm?.price - (itm?.price * itm?.discount) / 100;
        if (itm?.discount) {
          form.setFieldsValue({ [`discount_${itm?._id}`]: itm.discount });
        }
        return {
          label: itm?.serviceCode,
          value: itm?._id,
          price: itm?.price,
          discount: itm?.discount ?? 0,
          discountedPrice,
          description: itm?.description,
        };
      });
      return services;
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message,
      });
      return [];
    }
  };

  const onSelectProvider = async ({ target: { value } }) => {
    try {
      const provider = { providerId: { label: value?.label, value: value?.value } };
      form.setFieldsValue(provider);
      let providerId = provider?.providerId?.value?._id;
      if (value?.value === 'all') {
        providerId = 'all';
      }
      const services = await getServices(providerId);
      setState(prev => ({ ...prev, providerId: provider, services }));
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message,
      });
    }
  };

  const handleDiscount = (serviceId, value) => {
    const updatedServices = state?.services?.map(itm => {
      if (itm?.value === serviceId) {
        const discountedPrice = itm?.price - (+value / 100) * itm?.price;
        form.setFieldsValue({
          [`discount_${serviceId}`]: value,
        });

        return { ...itm, discount: value, discountedPrice };
      }
      return itm;
    });
    setState(prev => ({ ...prev, services: updatedServices }));
  };

  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      onTouched={_ => {
        setState(__ => ({ ...__, ..._ }));
      }}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label={LangConverter('Reseller', lang.value)}
          name="resellerId"
          open
          async
          defaultOptions
          isDisabled={!!price?.resellerId?.email}
          filterOption={false}
          loadOptions={handleResellerSearch}
          placeholder={LangConverter('Select Reseller', lang.value)}
          rules={[{ required: true, message: LangConverter('Please select Reseller', lang.value) }]}>
          <Select />
        </Form.Item>
        <Form.Item
          sm
          type="text"
          label={t('Service Provider')}
          name="providerId"
          open
          async
          defaultOptions
          isDisabled={!state?.resellerId?.value}
          filterOption={false}
          loadOptions={handleProviderSearch}
          onChange={onSelectProvider}
          placeholder={t('Select Service Provider')}
          rules={[{ required: true, message: t('Please select service provider') }]}>
          <Select />
        </Form.Item>
      </Grid>
      <ServicesHolder>
        {state?.services?.map(itm => (
          <Grid xs={1} md={12} gap={10}>
            <>
              <GridCol xs={1} sm={2}>
                <Form.Item
                  type="text"
                  sm
                  label={t('Service Code')}
                  name="serviceId"
                  placeholder={t('Service Code')}
                  disabled
                  value={itm?.label}>
                  <Field />
                </Form.Item>
              </GridCol>
              <GridCol xs={1} sm={2} md={3}>
                <Form.Item
                  type="text"
                  sm
                  label={t('Service Description')}
                  name="description"
                  placeholder={t('Service Description')}
                  disabled
                  value={itm?.description}>
                  <Field />
                </Form.Item>
              </GridCol>
              <GridCol xs={1} sm={2}>
                <Form.Item
                  type="number"
                  sm
                  label="Discount"
                  name={`discount_${itm?.value}`}
                  placeholder={t('Discount')}
                  onChange={({ target: { value } }) => {
                    handleDiscount(itm?.value, value);
                  }}
                  prefix="%"
                  rules={[
                    {
                      min: 0,
                      message: t('Minimum value should be 0'),
                    },
                    {
                      max: 100,
                      message: t('Value should be less than or equal to 100'),
                    },
                  ]}>
                  <Field />
                </Form.Item>
              </GridCol>
              <GridCol xs={1} sm={2}>
                <Form.Item
                  disabled
                  type="number"
                  sm
                  label={t('Original Price')}
                  name="price"
                  value={itm?.price}
                  placeholder={t('Price')}
                  prefix="€">
                  <Field />
                </Form.Item>
              </GridCol>
              <GridCol xs={1} sm={2} md={3}>
                <Form.Item
                  disabled
                  type="number"
                  sm
                  label={t('Discounted Price')}
                  name={`discounted_price_${itm?.value}`}
                  placeholder={t('Price')}
                  value={itm?.discountedPrice}
                  prefix="€">
                  <Field />
                </Form.Item>
              </GridCol>
            </>
          </Grid>
        ))}
      </ServicesHolder>

      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        {price ? LangConverter('Update', lang.value) : LangConverter('Save', lang.value)}
      </Button>
    </Form>
  );
}

export default CreatePrice;
