/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import Compress from 'react-image-file-resizer';

import { DateTime } from 'luxon';
/**
 * Sets a cookie with the given name, value and expiration time.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} days - The number of days until the cookie expires.
 * @returns {boolean} - Returns true if the cookie was successfully set.
 */
export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;

  return true;
};

/**
 *
 * @param {*} name
 * @returns
 */
export const getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

/**
 * Clears a cookie by setting its expiration date to a date in the past.
 * @param {string} name - The name of the cookie to clear.
 * @returns {boolean} - Returns true if the cookie was cleared successfully.
 */
export const clearCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  return true;
};

/**
 * Capitalizes the first letter of each word in a string.
 *
 * @param {string} str - The string to capitalize.
 * @returns {string} The capitalized string.
 */
export const capitalize = (str = '') => {
  const arr = str.toLowerCase().split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2;
};

/**
 * Returns a Date object based on the input string.
 * If the input string is invalid, returns the current date and time in the Canada/Eastern timezone.
 * @param {string} e - The input string to parse as a date.
 * @returns {Date} - A Date object representing the parsed date.
 */
export const getDateObject = (e = '') => {
  const date = new Date(e);
  date.toLocaleTimeString('en-US', { timeZone: 'Europe/Rome' });
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date?.getTime())) {
    const x = new Date();
    x.toLocaleTimeString('en-US', { timeZone: 'Europe/Rome' });
    return x;
  }
  return date;
};

/**
 * Converts a given amount to currency format.
 * @param {string|number} amount - The amount to be converted to currency format.
 * @returns {string} The amount in currency format.
 */
export const convertToCurrencyFormat = (amount = '0') =>
  `€ ${Number(amount)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
/**
 * Converts a given amount to currency format.
 * @param {string|number} amount - The amount to be converted to currency format.
 * @returns {string} The amount in currency format.
 */
export const formatServiceType = (type = '') => {
  if (type === 'courses') {
    return 'Typing Courses';
  }
  if (type === 'eipass') {
    return 'Eipass Typing Courses';
  }
  if (type === 'safety') {
    return 'Safety Typing Courses';
  }
  return null;
};
/**
 * Shortens a given string to a specified length and appends ellipsis if necessary.
 *
 * @param {string} str - The string to shorten.
 * @param {number} [len=10] - The maximum length of the shortened string.
 * @returns {string} The shortened string.
 */
export const shortenString = (str, len = 10) => {
  if (str.length > len) {
    return `${str.substring(0, len)}...`;
  }
  return str;
};

// eslint-disable-next-line no-unused-vars
/**
 * Converts time in seconds to a human-readable format.
 * @param {number} seconds - The time in seconds to be converted.
 * @param {boolean} [dontShowSeconds=true] - Whether to exclude seconds from the result.
 * @returns {string} The time in a human-readable format.
 */
export const timeConverter = (seconds, dontShowSeconds = true) => {
  const days = Math.floor(seconds / (60 * 60 * 24));
  const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
  const mins = Math.floor((seconds % (60 * 60)) / 60);
  const secs = seconds % 60;

  let result = '';
  // if (days > 0) {
  //   result += `${days}d `;
  // }
  if (hours > 0) {
    const tHours = days * 24;
    result += `${Number(hours) + tHours}h `;
  }
  if (mins > 0) {
    result += `${mins}m `;
  }
  if (secs > 0) {
    result += `${secs}s`;
  }
  if (dontShowSeconds && (days > 0 || hours > 0)) {
    result = result.replace(`${secs}s`, '');
  }

  return result.trim();
};

/**
 * Converts content to the specified language.
 * @param {string} content - The content to be converted.
 * @param {string} lang - The language to convert the content to. Default is 'en'.
 * @returns {string} The converted content.
 */
export const LangConverter = (content = '', lang = 'en') => {
  const toLowerCaseKeysObject = obj => {
    const newObj = {};
    for (const key in obj) {
      newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
  };
  const ItalianVocab = {
    'sign in': 'Accedi',
    'hello there! sign in': 'Benvenuto, entra in piattaforma!',
    'your email': 'Inserisci email',
    'your password': 'Inserisci password',
    name: 'Nome',
    profile: 'Profilo',
    actions: 'Azione',
    settings: 'Impostazioni',
    updated: 'Aggiornato',
    created: 'Creato',
    'profile settings': 'Impostazioni profilo',
    logout: 'Disconnettiti',
    firstName: 'Nome',
    'first name': 'Nome',
    'please enter first name': 'Campo obbligatorio',
    lastName: 'Cognome',
    'last name': 'Cognome',
    'please enter last name': 'Campo obbligatorio',
    username: 'Username',
    'user name': 'Username',
    'user since': 'Utente dal',
    'user role': 'Ruolo utente',
    'please enter username': 'Campo obbligatorio',
    password: 'password',
    'current password': 'Password attuale',
    'new password': 'Nuova password',
    'password updated successfully': 'Password aggiornata correttamente',
    'forget password ?': 'Password dimenticata?',
    'reset password': 'Invia email',
    'password is required': 'Campo obbligatorio',
    'enter your email so we can send you a password reset email':
      'Inserisci la tua email in modo da poterti inviare un link per reimpostare la password',
    'already have an account? ': 'Hai già un account?',
    email: 'Email',
    'e-mail': 'Email',
    'email is required.': 'Campo obbligatorio',
    'codice fiscale': 'Codice Fiscale',
    'codice fiscale is required': 'Campo obbligatorio',
    'partita iva': 'Partita Iva',
    'enter a valid 11 digits partita iva': 'Inserisci numero partita iva valido',
    'date of birth': 'Data di Nascita',
    'select date of birth': 'Seleziona la data di nascita',
    'select date range': 'Selezionare intervallo',
    'date of birth is required': 'Campo obbligatorio',
    'place of birth': 'Luogo di Nascita',
    'place of birth is required': 'Campo obbligatorio',
    'notification email': 'Email per notifiche',
    'notification email is required.': 'Campo obbligatorio',
    'phone number': 'Telefono',
    'phone number is required': 'Campo obbligatorio',
    'mobile number': 'Cellulare',
    address: 'Indirizzo',
    'address is required': 'Campo obbligatorio',
    'area code': 'Codice area',
    'area code is required': 'Campo obbligatorio',
    'zip code': 'CAP',
    'zip code is required': 'Campo obbligatorio',
    'telegram id': 'Telegram ID',
    dashboard: 'Pannello di controllo',
    'admin dashboard': 'Pannello di controllo',
    admins: 'Amministratori',
    'service providers': 'Servizi Fornitori',
    resellers: 'Rivenditori',
    'price list': 'Lista prezzi',
    'reseller prices': 'Prezzi rivenditori',
    'session calender': 'Calendario',
    'reseller services': 'Servizi Rivenditori',
    students: 'Studenti',
    'job house users': 'Utenti Job House',
    'online dashboard': 'Pannello di controllo online',
    'create permission': 'Crea permessi',
    'restore permissions': 'Ripristina permessi',
    'create role': 'Crea Ruolo',
    'create reseller': 'Crea Rivenditore',
    'create admin': 'Crea Admin',
    'create service': 'Crea Servizio',
    'create service provider': 'Crea Servizio Fornitore',
    'create price': 'Crea Prezzo',
    'create job house user': 'Crea utente Job House',
    'create student': 'Crea Studente',
    'create slot': 'Crea slot',
    'please buy this service to be able to book a session':
      'Acquistare questo servizio per poter prenotare una sessione',
    'place order': 'Effettua ordine',
    'edit order': 'Modifica ordine',
    'order detail': 'Dettagli ordine',
    details: 'Dettagli',
    route: 'Percorso',
    description: 'Descrizione',
    'please enter description': 'Campo obbligatorio',
    parent: 'Genitore',
    'select parent': 'Seleziona genitore',
    'select at least one role': 'Selezionare almeno un ruolo',
    'select at least one parent': 'Selezionare almeno un genitore',
    'update order status': 'Aggiorna stato ordine',
    'update order': 'Aggiorna ordine',
    'view payment proof': 'Visualizza anteprima',
    'proof of payment': 'prova di pagamento',
    'proof of payment is required': 'è richiesta la prova di pagamento',
    'upload payment proof': 'carica la prova di pagamento',
    previous: 'Precedente',
    'invalid page number': 'Numero di pagina non valido',
    'enter page number': 'Inserire numero di pagina',
    preview: 'Anteprima',
    showing: 'Mostra',
    of: ' di',
    all: 'tutti',
    results: 'risultati',
    'result per page': 'Risultato per pagina',
    'created at': 'Creato il',
    'updated at': 'Aggiornato il',
    type: 'Tipologia',
    'role type': 'Tipologia ruolo',
    'please enter role': 'Inserisci ruolo',
    'role type must be uppercase and onlu alphabets and _ are allowed':
      'Il tipo di nome del ruolo deve essere maiuscolo e sono ammessi solo gli alfabeti e caratteri speciali.',
    reseller: 'Rivenditore',
    'select reseller': 'Seleziona rivenditore',
    'plese select reseller': 'Obbligatorio selezionare il rivenditore',
    student: 'Studente',
    'select student': 'Seleziona studente',
    'please select studente': 'Obbligatorio selezionare lo studente',
    'edit student': 'Modifica studente',
    'student email': 'Email studente',
    'student name': 'Nome studente',
    'session created': 'Sessione creata',
    processed: 'In corso',
    'post exam': 'In esame',
    'certificate wait': 'Attesa certificato',
    closed: 'Chiuso',
    'session date': 'Data sessione',
    'session date is required': 'Data sessione è richiesta',
    'start time': 'Inizio',
    'start time is required': 'Data inizio tempo richiesto',
    'end time': 'Fine',
    'end time is required': 'Data fine tempo richiesta',
    'book session': 'Sessione di prenotazione',
    'delete session': 'Elimina sessione',
    today: 'Oggi',
    month: 'Mese',
    week: 'Settimana',
    day: 'Giorno',
    list: 'Lista',
    date: 'Data',
    prev: 'Precedente',
    next: 'Successivo',
    'free slot': 'SLOT LIBERO',
    'booked by': 'Prenotato da',
    'please select a valid date to create a slot': 'Selezionare una data valida per creare uno slot',
    'select all': 'Seleziona tutto',
    'select all groups permissions': 'Selezionare tutte le autorizzazioni dei gruppi',
    'search permission group': 'Cerca gruppi di autorizzazioni',
    'search permissions': 'Cerca autorizzazioni',
    'customize permissions': 'Modifica autorizzazioni',
    'permission saved successfully': 'Autorizzazione salvata correttamente',
    'please enter can': 'Inserisci campo',
    can: 'Può entrare su',
    'can can only contain letters,underscores and dashes': 'Può contenere solo lettere, trattini e trattini bassi.',
    'maximum character length is 40': 'La lunghezza massima dei caratteri è 40',
    services: 'Servizi',
    'select service': 'Seleziona servizio',
    'please select service': 'Obbligatorio selezionare un servizio',
    'please select a service': 'Obbligatorio selezionare un servizio',
    'service code': 'Codice servizio',
    'please enter service code': 'Inserisci codice servizio',
    'no records found': 'Nessun record trovato',
    'per unit price': 'Prezzo unitario',
    'please enter price': 'Inserire il prezzo',
    price: 'Prezzo',
    'original price': 'Prezzo originale',
    'original amount': 'Importo originale',
    discount: 'Sconto',
    'minimum values should be 1': 'Il valore minimo è 1',
    'value should be less than or equal to 100': 'Il valore deve essere inferiore o uguale a 100',
    create: 'Crea',
    update: 'Aggiorna',
    upload: 'Carica',
    save: 'Salva',
    'discounted price': 'Prezzo scontato',
    certificate: 'Certificato',
    'certificate is required': 'Inserisci certificato',
    'please select a status': 'Seleziona lo stato',
    'select status': 'Seleziona stato',
    'select a status': 'Seleziona uno stato',
    status: 'stato',
    rejected: 'Rifiutato',
    accepted: 'Accettato',
    cancelled: 'Cancellato',
    pending: 'In attesa',
    units: 'unità',
    'units remaining': 'Unità rimanenti',
    'payment proof': 'Prova di pagamento',
    'total amount': 'Importo totale',
    'total price': 'Prezzo totale',
    '(no. of units * price)': '(numero di unità * prezzo)',
    'admin deleted successfully': 'Admin eliminato correttamente',
    'error deleting admin': 'Errore eliminazione admin',
    'edit admin': 'Modifica Admin',
    'admin detail': 'Dettagli Admin',
    'are you sure you want to delete this record?': 'Cancellare questo record?',
    'are you sure you want to delete this session?': 'Cancellare questa sessione?',
    "you can't undo this action": 'non è possibile annullare questa azione',
    'error in importing': 'Errore nella importazione',
    'session detail': 'Dettagli sessione',
    'session details': 'Dettagli della sessione',
    'more actions': 'Altre azioni',
    'extra info': 'Ulteriori informazioni',
    'update extra info': 'aggiorna ulteriori informazioni',
    'change status': 'Cambia stato',
    'update status': 'Aggiorna stato',
    'upload certificate': 'Carica certificato',
    'deleted successfully': 'Eliminato correttamente',
    'permission deleted successfully': 'Permessi rimossi correttamente',
    'pricelist deleted successfully': 'Listino prezzi cancellato correttamente',
    'error deleting pricelist': 'Errore di cancellazione del listino prezzi',
    'error deleting': 'Errore',
    'edit reseller price': 'Modifica prezzo rivenditore',
    'edit reseller': 'Modifica rivenditore',
    'edit service provider': 'Modifica fornitore',
    'service provider': 'Fornitore',
    'select service provider': 'Seleziona servizio fornitore',
    'please select service provider': 'Selezionare il servizio del fornitore',
    providername: 'Nome fornitore',
    provider: 'Fornitore',
    'failed to fetch certificate': 'Caricamento fallito',
    'certificate.png': 'certificato.png',
    'download certificate': 'Download Certificato',
    'today stoppages': 'Oggi fermi',
    'daily stoppages': 'Fermi giornalmente',
    stoppages: 'Interruzioni',
    users: 'Utente',
    interval: 'Intervallo',
    'create especiales': 'Creare speciali',
    'create embalaje': 'Creare un embalaje',
    'create comment': 'Creare un commento',
    'edit role': 'Modifica ruolo',
    comments: 'Commento',
    roles: 'Ruoli',
    'you don`t have permission to access this , kindly contact Learn Platform for more details':
      'Non hai il permesso di accedere a questo sito, contatta gentilmente Learn Platform per maggiori dettagli.',
    'role deleted successfully': 'Ruolo eliminato correttamente',
    'role saved successfully': 'Ruolo salvato correttamente',
    'error deleting role': 'Errore, ruolo non eliminato',
    permissions: 'Autorizzazioni',
    'no permissions': 'Nessuna autorizzazione',
    'update company info': 'Aggiornare le informazioni azienda',
    'add user': 'Aggiunge utente',
    'all lines production summary': 'Riepilogo della produzione di tutte le linee',
    'shift summary': 'Riepilogo del turno',
    machines: 'Produzione in linea',
    shifts: 'Resoconto del turno',
    search: 'Ricerca',
    'search...': 'Cerca...',
    'date range': 'Intervallo di date',
    'filter motive': 'Filtro motivo',
    'filter machine': 'Filtro produzione',
    'filter turno': 'Filtro turno',
    'select language': 'Seleziona lingua',
    'no data available !!!': 'Nessuna data disponibile !!!',
    daily: 'Giornaliero',
    weekly: 'Semestrale',
    monthly: 'Mensile',
    '6 months': '6 Mesi',
    yearly: 'Annuale',
    custom: 'Personalizza',
    'Total Units': 'Unità totali',
    'No file found': 'Nessun file trovato',
    'Order Management': 'Gestione ordini',
    'File is required': 'File richiesto',
    'Payment Commitment Self Certification': 'Certificazione di impegno al pagamento',
    'Accepted & Paid': 'Accettato e pagato',
    'Discounted Amount': 'Importo scontato',
    'Order Number': 'Ordine Numero',
    'reseller dashboard': 'dashboard rivenditore',
    'reseller Type': 'Tipo rivenditore',
    'Order Status': 'Stato ordine',
    'comission amount': 'Importo della commissione',
    'Order History': 'Cronologia ordini',
    'Comission Status': 'Stato commissione',
    Time: 'Tempo',
    'Remaining Service Units': 'Unità di servizio rimanenti',
    'Provider Name': 'Nome fornitore',
    'Supplier Type': 'tipologia del fornitore',
    All: 'tutto',
    'Comission Paid': 'Commissione Pagata',
    'Update Order Status': "Aggiorna Stato dell'Ordine",
    'Reseller Price Lists': 'Liste prezzi rivenditori',
    'Create Price List': 'Crea lista prezzi',
    'Create Reseller Price List': 'Crea lista prezzi rivenditore',
    Inventory: 'Inventario',
    'View Inventory': 'Visualizza inventario',
    Commissions: 'Commissioni',
    'View Commissions': 'Visualizza commissioni',
    'Last Updated': 'Ultimo aggiornamento',
    'Edit Session Booking': 'Modifica Prenotazione Sessione',
    'Edit Session': 'Modifica Sessione',
    'Update Session': 'Aggiorna Sessione',
    'Reason is required': 'Motivo richiesto',
    Reason: 'Motivo',
    Booked: 'Prenotato',
    Sent: 'Inviato',
    'Pre Registeration document': 'Documento di Pre-Registrazione',
    'Educational Certificate': 'Certificato Educativo',
    'Upload Pre Registeration document': 'Carica Documento di Pre-Registrazione',
    'Upload Educational Certificate': 'Carica Certificato Educativo',
    'service type': 'tipo di servizio',
    'Max Attendees': 'Numero Massimo di Partecipanti',
    'Edit Booking': 'Modifica Prenotazione',
    'Booked Slots': 'Posti Prenotati',
    Instructions: 'Istruzioni',
    Qualification: 'Qualifica',
    'Year of Pre Enrollment': 'Anno di Pre-Iscrizione',
    'Upload Documents': 'Carica Documenti',
    'Enrolled Courses': 'Corsi Iscritti',
    'No bookings found': 'Nessuna prenotazione trovata',
    'Export Session': 'Esporta Sessione',
    'Create Seller': 'Crea Venditore',
    'Seller Email': 'Email Venditore',
    'Seller Name': 'Nome Venditore',
    'Reseller Name': 'Nome Rivenditore',
    'Reseller Email': 'Email Rivenditore',
    'Download Payment Commitment': 'Scarica impegno di pagamento',
    'Switch to monthly view': 'Visualizza per mese',
    'Close monthly view': 'Chiudi vista mensile',
    'Update Orders': 'Aggiorna ordini',
    'Change Status for selected orders?': 'Cambiare lo stato degli ordini selezionati?',
    record: 'registrazione',
    records: 'registrazioni',
    'Select Month': 'Seleziona Mese',
    'Unable to generate certificate': 'Impossibile generare il certificato',
    payment_commitment_order: 'ordine di impegno di pagamento',
    self_certification_order: 'ordine_di_autocertificazione',
    'Self Certification': 'Autocertificazione',
    'Download Self Certification': 'Scarica Autocertificazione',
    'View Self Certification': 'Visualizza Autocertificazione',
    'Upload Self Certification': 'Carica Autocertificazione',
    'As legal representative, I declare that I have authorization from the student to collect the certificates in paper or digital format on his behalf':
      'In qualità di legale rappresentante dichiaro di essere in possesso di autorizzazione da parte dello studente a ritirare per suo conto gli attestati in formato cartaceo o digitale',
    'Please Check the Terms and Conditions': 'Si prega di verificare i termini e le condizioni',
    'Total Amount Paid': 'Totale Pagato',
    'Total Pending Amount:': 'Totale Importo in sospeso:',
    'Service Description': 'Descrizione Servizio',
    'Create Service Package': 'Crea Pacchetto Servizio',
    'Package Price': 'Prezzo Pacchetto',
    'Please enter package price': 'Si prega di inserire il prezzo del pacchetto',
    'Please enter package name': 'Si prega di inserire il nome del pacchetto',
    'Enter Package Name': 'Inserisci Nome Pacchetto',
    'Package Name': 'Nome Pacchetto',
    'Edit Service Package': 'Modifica Pacchetto Servizio',
  };

  if (lang === 'it' && content) {
    return toLowerCaseKeysObject(ItalianVocab)[content.trim().toLowerCase().replace(/-/, ' ')] ?? content;
  }

  return content;
};

// eslint-disable-next-line no-unused-vars
export function getShiftDateForCompany(company_name = '', shifts_info = {}) {
  const companyShiftTimings = shifts_info.reduce((a, b) => {
    if (!a[b.EMPRESA.toLowerCase()]) {
      a[b.EMPRESA.toLowerCase()] = {};
    }
    if (!a[b.EMPRESA.toLowerCase()][b.TURNO]) {
      a[b.EMPRESA.toLowerCase()][b.TURNO] = {};
    }
    a[b.EMPRESA.toLowerCase()][b.TURNO] = {
      start: b.INICIO,
      end: b.FIN,
    };
    return a;
  }, {});
  const shiftTiming = companyShiftTimings[company_name.toLowerCase()];
  const dateTimeBuenosAires = DateTime.now().setZone('America/Argentina/Buenos_Aires');
  let shiftStartDate = dateTimeBuenosAires.set({
    hour: shiftTiming['TURNO 1'].start.split(':')[0],
    minute: shiftTiming['TURNO 1'].start.split(':')[1],
    second: 0,
  });
  if (dateTimeBuenosAires.diff(shiftStartDate, 'seconds') < 0) {
    shiftStartDate = dateTimeBuenosAires.minus({ days: 1 });
  }
  return shiftStartDate.toFormat('yyyy-MM-dd');
}
export function isoToArgentinianDate(iso = '', format = 'yyyy-MM-dd HH:mm:ss') {
  const date = DateTime.fromISO(iso).setZone('America/Argentina/Buenos_Aires');
  return date.toFormat(format);
}
export function isoToItalianDate(iso = '', format = 'dd-MM-yyyy HH:mm', isIso = false) {
  const date = DateTime.fromISO(iso).setZone('Europe/Rome');
  if (isIso) {
    return date.toISO({ includeOffset: false });
  }
  return date.toFormat(format);
}

export const compressImage = (file, type = 'PNG ') =>
  new Promise(resolve => {
    Compress.imageFileResizer(
      file,
      255,
      90,
      type,
      100,
      0,
      uri => {
        resolve(uri);
      },
      'base64',
    );
  });

export const convertFileBase64 = file =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });

export const formatResellerType = type => {
  switch (type) {
    case 'basic':
      return 'Baisc';
    case 'premium':
      return 'Premium';
    case 'standard':
      return 'Standard';
    default:
      return type;
  }
};

export const getComissionAmount = order => {
  if (order?.resellerId?.typeOfAuthorizedCenter !== 'basic') {
    return '---';
  }
  if (order?.servicesDetail?.length) {
    const comission = order.servicesDetail.reduce((prev, curr) => {
      const discountUnitsSum = curr.discount * curr.units;
      return prev + discountUnitsSum;
    }, 0);
    return convertToCurrencyFormat(comission ?? 0);
  }
  return '---';
};
