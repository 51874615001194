/* eslint-disable no-unreachable */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import { LangConverter } from 'helpers/common';
import Select from 'components/atoms/Select';
import GridCol from 'components/atoms/GridCol';
import resellerService from 'services/resellers.service';
import { useTranslation } from 'helpers/useTranslation';

function PlaceOrderForm({ onClose = () => {}, order }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { refetch, user } = useContextHook(AuthContext, ['refetch', 'user']);
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  const { t } = useTranslation();
  console.log({ order });
  useEffect(() => {
    if (order) {
      const services = order.servicesDetail?.map(itm => {
        form.setFieldsValue({ [`units_${itm?.serviceId?._id}`]: itm?.units });
        const filteredPackage = order?.packagesDetail?.find(pckg => pckg?.serviceId === itm?.serviceId?._id)?.packageId;
        console.log({ filteredPackage });
        return {
          label: !itm?.packageId
            ? `${t('Service Code')}: ${itm?.serviceId?.serviceCode} ${t('Provider')}:${
                itm?.serviceId?.providerId?.providerName
              }`
            : `${t('Package Name')}: ${filteredPackage?.name} ${t('Service Code')}: ${itm?.serviceId?.serviceCode} ${t(
                'Provider',
              )}:${itm?.serviceId?.providerId?.providerName}`,
          value: !itm?.packageId ? itm?.serviceId?._id : itm?.packageId,
          serviceCode: itm?.serviceId?.serviceCode,
          price: itm?.packageId ? filteredPackage?.packagePrice : itm?.serviceId?.price,
          discountedAmount:
            itm?.originalAmount / itm?.units - ((itm?.originalAmount / itm?.units) * itm?.discount) / 100,
          units: itm?.units,
          totalPrice: itm?.amount,
          package: itm?.packageId,
          serviceId: itm?.serviceId?._id,
        };
      });
      const s = {
        serviceId: services,
        paymentProof: order?.paymentProof ? order?.paymentProof : '',
        paymentCommitment: order?.paymentCommitment ?? '',
      };
      form.setFieldsValue(s);
      setState(prev => ({ ...prev, ...s }));
    }
  }, [order]);

  const totalAmount = useMemo(() => {
    if (state?.serviceId?.length) {
      const total = state.serviceId.reduce((accumulator, currentValue) => {
        // Check if currentValue has a 'totalPrice' property and it's a number
        if (currentValue && typeof currentValue?.totalPrice === 'number') {
          return accumulator + currentValue?.totalPrice;
        }
        return accumulator;
      }, 0); // Initialize accumulator with 0
      return total;
    }
    return 0;
  }, [state]);

  const handleServices = async __ => {
    try {
      const response = await resellerService.getResellerPricesWithPackages({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const individualServices = response?.items?.filter(itm => !itm?.package);
      const packages = response?.items?.filter(itm => itm?.package);

      const options = individualServices?.map(_ => ({
        value: _?._id,
        label: `${t('Service Code')}: ${_?.serviceCode} ${t('Provider')}:${_?.providerId?.providerName}`,
        serviceCode: _?.serviceCode,
        providerId: _?.providerId,
        providerName: _?.providerId?.providerName,
        price: _?.price,
        discountedAmount: _?.discountedAmount,
        totalPrice: user?.typeOfAuthorizedCenter === 'basic' ? _?.price : _?.discountedAmount,
        units: null,
      }));

      const packagesOptions = packages?.map(_ => ({
        value: _?._id,
        label: `${t('Package Name')}: ${_?.name} ${t('Service Code')}: ${_?.serviceId?.serviceCode} ${t('Provider')}:${
          _?.serviceId?.providerId?.providerName
        }`,
        serviceCode: _?.serviceId?.serviceCode,
        providerId: _?.serviceId?.providerId?._id,
        providerName: _?.serviceId?.providerId?.providerName,
        price: _?.packagePrice,
        discountedAmount: 0,
        totalPrice: _?.packagePrice,
        units: _?.units,
        package: true,
        serviceId: _?.serviceId?._id,
      }));
      return [...options, ...packagesOptions];
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };

  console.log({ state });

  const handleGenerateSelfCertificate = async pdf => {
    try {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([pdf]));
      a.download = `${t('self_certification_order')}_${order?.orderNumber}.pdf`;
      a.click();
      document.body.appendChild(a);
      document.body.removeChild(a);
    } catch (error) {
      Toast({ type: 'error', message: error?.message });
    }
  };

  const onSubmit = async () => {
    const packagesDetail = [];
    const servicesDetail = state?.serviceId?.map(itm => {
      if (itm?.package) {
        const detail = {
          packageId: itm?.value,
          serviceId: itm?.serviceId,
          units: itm?.units,
          originalAmount: itm?.price,
        };
        packagesDetail.push(detail);
        return detail;
      }
      return {
        serviceId: itm?.value,
        units: itm?.units,
        originalAmount: itm?.price * itm?.units,
      };
    });

    const payload = {
      servicesDetail,
      packagesDetail,
      paymentProof: state?.paymentProof,
      paymentCommitment: state?.paymentCommitment,
    };
    console.log({ payload, serviceId: state?.serviceId });
    // return;
    try {
      setLoading(true);
      const res = !order
        ? await resellerService.placeOrder(payload)
        : await resellerService.updateOrder(order._id, payload);
      if (!order && user?.typeOfAuthorizedCenter === 'premium') {
        try {
          const resp = await resellerService.generateSelfCertificate(res?.serviceOrder?._id);
          handleGenerateSelfCertificate(resp);
        } catch {
          console.log('pdf not generated');
        }
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  const handleSelectServices = ({ target: { value } }) => {
    const services = value?.map(itm => {
      const filteredVal = state?.serviceId?.find(item => item?.value === itm?.value);
      return {
        ...itm,
        units: filteredVal?.units ?? itm?.units,
      };
    });
    form.setFieldsValue({ serviceId: services });
  };

  const handleUnits = (serviceId, units) => {
    const services = state?.serviceId?.map(itm => {
      if (itm?.value === serviceId) {
        let newPrice = units * itm?.discountedAmount;
        if (user?.typeOfAuthorizedCenter === 'basic') {
          newPrice = units * itm?.price;
        }
        return { ...itm, totalPrice: newPrice, units };
      }
      return itm;
    });
    form.setFieldsValue({ [`units_${serviceId}`]: units });
    setState(prev => ({ ...prev, serviceId: services }));
  };
  return (
    <Form
      form={form}
      onSubmit={onSubmit}
      onTouched={_ => {
        setState(__ => ({ ...__, ..._ }));
      }}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <GridCol xs={1} sm={12}>
          <Form.Item
            sm
            type="text"
            label={t('Services')}
            name="serviceId"
            open
            async
            defaultOptions
            isMulti
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            filterOption={false}
            onChange={handleSelectServices}
            loadOptions={handleServices}
            placeholder={t('Select Service')}
            rules={[{ required: true, message: t('Please select a service') }]}>
            <Select />
          </Form.Item>
        </GridCol>
      </Grid>
      <div>
        {state?.serviceId?.map(itm => (
          <Grid xs={1} md={12} gap={10}>
            <GridCol xs={1} sm={2} md={3}>
              <Form.Item
                type="text"
                sm
                label="Service Code"
                name="serviceId"
                placeholder="Service Code"
                disabled
                value={itm?.serviceCode}>
                <Field />
              </Form.Item>
            </GridCol>
            <GridCol xs={1} sm={2} md={3}>
              <Form.Item
                type="number"
                sm
                label="Units"
                disabled={itm?.package}
                name={`units_${itm?.value}`}
                value={itm?.units}
                placeholder="Units"
                onChange={({ target: { value } }) => {
                  handleUnits(itm?.value, value);
                }}
                rules={[
                  {
                    required: !itm.package,
                    message: 'Minimum value should be 1',
                  },
                  {
                    min: 1,
                    message: 'Minimum value should be 1',
                  },
                ]}>
                <Field />
              </Form.Item>
            </GridCol>
            <GridCol xs={1} sm={2} md={3}>
              <Form.Item
                type="number"
                sm
                label="Per Unit Price"
                name="price"
                placeholder="Price"
                disabled
                value={user?.typeOfAuthorizedCenter === 'basic' ? itm?.price : itm?.discountedAmount}
                prefix="€">
                <Field />
              </Form.Item>
            </GridCol>
            <GridCol xs={1} sm={2} md={3}>
              <Form.Item
                type="number"
                sm
                label="Total Price"
                name="totalPrice"
                placeholder="units * price"
                disabled
                value={itm?.totalPrice}
                prefix="€">
                <Field />
              </Form.Item>
            </GridCol>
          </Grid>
        ))}
      </div>

      {!!state?.serviceId?.length && (
        <>
          <GridCol xs={1}>
            <Form.Item
              type="number"
              sm
              label="Total Order Amount"
              name="totalOrderAmount"
              placeholder="Total Order Amount"
              disabled
              value={totalAmount}
              prefix="€">
              <Field />
            </Form.Item>
          </GridCol>
          <GridCol xs={1}>
            {user?.typeOfAuthorizedCenter === 'premium' && (
              <Form.Item
                type="chooseFile"
                label={t('Self Certification')}
                name="paymentCommitment"
                pdf
                value={state?.paymentCommitment}
                onChange={({ target }) => {
                  setState(prev => ({ ...prev, paymentCommitment: target?.value }));
                  form.setFieldsValue({ paymentCommitment: target?.value });
                }}
                // rules={[
                //   {
                //     required: () => {
                //       if (user?.typeOfAuthorizedCenter !== 'gold') {
                //         return true;
                //       }
                //       return false;
                //     },
                //     message: 'Payment Proof is required',
                //   },
                // ]}
              >
                <Field />
              </Form.Item>
            )}
            <Form.Item
              type="chooseFile"
              label="Payment Proof"
              name="paymentProof"
              pdf
              value={state?.paymentProof}
              onChange={({ target }) => {
                setState(prev => ({ ...prev, paymentProof: target?.value }));
                form.setFieldsValue({ paymentProof: target?.value });
              }}
              // rules={[
              //   {
              //     required: () => {
              //       if (user?.typeOfAuthorizedCenter !== 'gold') {
              //         return true;
              //       }
              //       return false;
              //     },
              //     message: 'Payment Proof is required',
              //   },
              // ]}
            >
              <Field />
            </Form.Item>
          </GridCol>
        </>
      )}
      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        {LangConverter(order ? 'Update' : 'Save', lang.value)}
      </Button>
    </Form>
  );
}

export default PlaceOrderForm;
